<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  
    
    <v-card class="card">
      <v-card-title class="heading justify-center">Book History</v-card-title>
      <v-card-text> <br/>
            <v-row>
            <v-col cols="12" sm="3">
             <!-- <v-autocomplete :items="itemlist"
            rounded
            outlined
            item-text="name"
            item-value="id"
            v-model="seletedbook"
            
            ></v-autocomplete>  -->
             <!-- <input type="text" id="grno" @onkeyup="myFunction()" v-model="seletedbook" required>
            <table id="myTable" style="display:none;background:#e9e9e6;box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.30), 0 10px 30px 0 rgba(0, 0, 0, 0.30);">
                                      <tr v-for="item1 in itemlist" :key="item1.name">
                                            <td>{{ item1.name }}</td>
                                        </tr>
            </table> -->
             <v-checkbox
                              
                             class="pr-3"
                                v-model="seaechbyacc1"
                                label="Search By Accession No"
                                dense
                                hide-details
                                @click="group('one')"
                              ></v-checkbox>
                              </v-col>
                              <v-col cols="12" sm="3">
                               <v-checkbox
                               
                                v-model="seaechbyacc2"
                                label="Search By Title"
                                @click="group('two')"
                                dense
                                hide-details
                              ></v-checkbox>
                              </v-col>
                              </v-row>
             <v-row>
             <v-col cols="12" sm="3" style="margin-top:30px">
               <v-combobox
               style="padding:0px; margin:0px;"
                v-model="seletedbook"
                :items="allBookInfo"
                item-text="displayname"
                placeholder="Select Book"
                outlined
                return-object
                dense
                clearable
                required
                @input.native="onload1($event.srcElement.value)"
                @click="isbookselected=false"
                @change="set1(selectedbookitem, 'book')"
              ></v-combobox>
              </v-col>
              
             <!-- <v-autocomplete
                v-model="seletedbook"               
                :items="allBookInfo"
                item-value="blfid"
                item-text="displayname"
                outlined                              
                dense                
                clearable
                required
                @input.native="onload1($event.srcElement.value)"
              ></v-autocomplete> -->
            
            <v-col cols="12" sm="3">
                <label class="pl-5">From Date</label>

                <br />
                <!-- {{formdata.resedential_premit_issue_date}} -->
                <input
                  type="date"
                  placeholder="From Date"
                  class="example"
                  v-model="selectedfromdate"
                
                />
              </v-col>
              <v-col cols="12" sm="3">
                <label class="pl-5"> To Date</label>

                <br />
                <!-- {{formdata.resedential_premit_issue_date}} -->
                <input
                  type="date"
                  placeholder="To Date"
                  class="example"
                  v-model="selectedtodate"
                
                />
              </v-col>
               <v-col cols="12" sm="3">
               
<br/>
                <br />
                <!-- {{formdata.resedential_premit_issue_date}} -->
               <v-btn class="primary" @click="gethistory">Search<v-icon>mdi-search</v-icon></v-btn>
              </v-col>
            </v-row>
            
      </v-card-text>
    </v-card>
    
     <div class="pa-5 ma-5 grey lighten-3" v-if="Array.isArray(bookhistory) && bookhistory.length">
      <v-row justify="end">
      <v-btn   @click="exportexcel()" color="success" dark class="mb-2">
          <v-icon dark>mdi-file-excel</v-icon>
        </v-btn>
         </v-row>
        <h3>Book History</h3><br/>
     
         <v-simple-table class=" grey lighten-3"  id="exceltable" style="display:none;">
        <tbody>
          <tr>
          <td align="center">Emp no/Prn no</td>
          <td align="center">Name</td>
          <td align="center">Username</td>
          <td align="center">Return Date</td>
           <td align="center">Is Current Holder</td>
        </tr>
        {{bookhistory[0].return_date}}
          <tr v-for="(item,index) in bookhistory" :key='index'>
              <td >{{item.grno_empid}}</td>
               <td>{{item.name}}</td>
                <td>{{item.username}}</td>
                  <td v-if=" item.return_date!=''">{{item.return_date.split("T")[0].split("-")[2]+'-'+item.return_date.split("T")[0].split("-")[1]+"-"+item.return_date.split("T")[0].split("-")[0]}}</td>
            <td v-if="item.iscurrent == true">Yes</td>
             <td v-else>No</td>
          </tr>
        </tbody>
        </v-simple-table>
          <v-simple-table class=" grey lighten-3" >
      
        <thead>
          <th align="center">Emp no/Prn no</th>
          <th><center>Name</center></th>
          <th><center>Username</center></th>
          <th><center>Return Date</center></th>
           <th><center>Is Current Holder</center></th>
        </thead>
        <tbody>
          <tr v-for="(item,index) in bookhistory" :key='index'>
              <td >{{item.grno_empid}}</td>
               <td>{{item.name}}</td>
                <td>{{item.username}}</td>
                 <td v-if=" item.return_date!=''">{{item.return_date.split("T")[0].split("-")[2]+'-'+item.return_date.split("T")[0].split("-")[1]+"-"+item.return_date.split("T")[0].split("-")[0]}}</td>
           <td v-else></td>
            <td v-if="item.iscurrent == true"><i class="fa fa-check-circle fa-lg" style="color:green;" aria-hidden="true"></i></td>
             <td v-else><i class="fa fa-times-circle fa-lg" style="color:red;" aria-hidden="true"></i></td>
          </tr>
        </tbody>
        </v-simple-table>
        </div>
      
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import {table2excel} from "../jquery.table2excel";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
      allBookInfo:[],
      itemlist:[],
      bookhistory:[],
      seletedbook:"",
      selectedfromdate:null,
      selectedtodate:null,
      employeehistory:[],
    seaechbyacc2:false,
    seaechbyacc1:true,
    seaechbyacc:true,

      snackbar: false,
            snackbar_msg: "",
            color:""
  }),

  mounted() {
     this.selectedtodate = new Date().toJSON().slice(0,10);
                     //this.selectedfromdate = new Date().setMonth(this.selectedtodate.getMonth() - 1);
                     var d = new Date();
                      d.setMonth(d.getMonth() - 1);
                      this.selectedfromdate = d.toJSON().slice(0,10);
  },

  methods: {
      
       group(value)
    {
        if(value=='one')
        {
          this.seaechbyacc1=true;
          this.seaechbyacc2=false;
          this.seaechbyacc=true;
        }
        if(value=='two')
        {
          this.seaechbyacc1=false;
          this.seaechbyacc2=true;
          this.seaechbyacc=false;
        }
    },
    //      onload1(value) {
    //   if(value!="")
    //   {

    //   var params={text:value,seaechbyacc:true};
    //   axios
    //     .post("/IssueBook/getbooksinfo1",params)
    //     .then((res) => {
    //       if (res.data.msg == "200") {
    //         this.allBookInfo = res.data.allBookInfo;
    //       }
    //     })
    //     .catch((error) => {
    //       this.showSnackbar("#b71c1c", "Something Went Wrong"); // show snackbar
    //     })
    //     .finally(() => {
    //       // var overlay = false;
    //     });
    //   }
      
    // },
    onload1(value) {
      if(value!="")
      {

      var params={text:value,seaechbyacc:this.seaechbyacc};
      axios
        .post("/IssueBook/getbooksinfo1",params)
        .then((res) => {
          if (res.data.msg == "200") {
           

            this.allBookInfo = res.data.allBookInfo;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
      }
      
    },

     showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
      exportexcel() {
     
        $("#exceltable").table2excel({
    
    name: "Worksheet Name",
    filename: "BOOK_HISTORY", //do not include extension
    fileext: ".xls" // file extension
  }); 
   
    },
      gethistory(){
        //alert(this.seletedbook);
        var value="";
         if(typeof this.seletedbook  === 'object')
         value = this.seletedbook.accession_number;
        else  
        value = this.seletedbook
        
          var params={"blfid":value,fromdate:this.selectedfromdate,todate:this.selectedtodate};
             axios
                    .post("/Librarian/getBoohistory",params)
                    .then((res) => {
                      if(res.data.bookhistory.length != 0){
                     this.bookhistory= res.data.bookhistory;
                     }else{
                        this.showSnackbar("#b71c1c", "No History Available");
                     }
                    })
                   
      }
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.isdonated {
  margin-top: 15px;
  padding-left: 15px !important;
}
.check {
  color: green;
  font-size: 20px;
  padding-right: 10px;
}
.cross {
  color: red;
  font-size: 20px;
  padding-right: 5px;
}

.example {
  margin-top: 0px;
  background: #ffff;
  padding-left: 20px;
  border: 1px solid rgb(171, 169, 169);
  border-radius: 5px;
  height: 40px;
  width: 100%; 
}

.example:focus {
 
   border-radius: 50px;
   border-color: #2f74b0;
     border-width: 2px;
       
       outline: 0;
}

.required:after {
  content: " *";
  color: red;
}
.primary{
margin-top: -13px;
}
.date {
  border: 2px solid red;
}

</style>